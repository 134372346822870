import { QueryKey, useMutation, useQueryClient } from "@tanstack/react-query"

import { BackendLoginClient } from "src/constants/env"
import { API_DEFAULT } from "src/constants/minutApi"
import { setAxiosApiToken } from "src/data/auth/auth"
import { authTokenStorage, clearAuthStorage } from "src/data/auth/authStorage"
import { IAuthorization } from "src/data/user/user"
import { Routes } from "src/router/routes"
import { debug } from "src/utils/logger"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

const KEY_TOKEN: QueryKey = ["keyToken"]
/** Post a token to BE, store response in local storage */
export function usePostToken({ logout }: { logout: () => void }) {
  async function postToken({
    code,
    codeVerifier,
  }: {
    code: string
    codeVerifier: string
  }) {
    const response = await minutApiHttpClient.post<IAuthorization>(
      `${API_DEFAULT}/oauth/token`,
      {
        client_id: BackendLoginClient.clientId,
        client_secret: BackendLoginClient.clientSecret,
        code,
        code_verifier: codeVerifier,
        grant_type: "authorization_code",
        redirect_uri: `${window.location.origin}${BackendLoginClient.clientRedirectPath}`,
      }
    )

    return response.data
  }

  function logoutWithFallback() {
    try {
      logout()
    } catch (e) {
      debug.error("Hook logout failed, using fallback", e)
      clearAuthStorage()
      window.location.href = Routes.BELogout.location().pathname
    }
  }

  const cache = useQueryClient()

  return useMutation(postToken, {
    mutationKey: KEY_TOKEN,
    onSuccess: (tokenExchangeData: IAuthorization) => {
      cache.setQueryData(KEY_TOKEN, () => tokenExchangeData)
      authTokenStorage.set(tokenExchangeData)
      setAxiosApiToken({
        accessToken: tokenExchangeData.access_token,
        onFailedRefresh: logoutWithFallback,
      })
    },
  })
}
